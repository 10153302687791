import React from "react"
import '../styles/articles.css';
 
function Articles(props) {
  return (
    <div className="articles">
      <h1>Articles Component</h1>
    </div>
  )
}
 
export default Articles;